export type PageMeta = {
  url: string;
  title: string;
  description: string;
}

export const homeMeta: PageMeta = {
  url: '/',
  title: 'AILiveSim: Softwaresupplier for automation of vehicles',
  description: 'AI-driven AILiveSim, a premier software supplier, specializing in vehicle automation. Transform your processes with our advanced AI solutions.'
};

export const platformMeta: PageMeta =  {
  url: '/platform',
  title: 'Efficient & Scalable Simulation Ecosystem | AILiveSim',
  description: 'Experience AILiveSim\'s powerful suite of editors for precise control and automation. From local to cloud simulation, debug, analyze, and optimize scenarios effortlessly.'
};

export const maritimeMeta: PageMeta =  {
  url: '/Industries/maritime',
  title: 'AILiveSim: Automated Marine Vehicles Solutions',
  description: 'AILiveSim\'s cutting-edge solutions for automated marine vehicles. Enhance efficiency and reliability with our advanced automation technology.'
};
export const mobileMeta: PageMeta = {
  url: '/Industries/mobile-machines',
  title: 'Advanced Mobile Machine Simulation with AILiveSim',
  description: 'Experience realistic environments, dynamic data generation, and accurate dynamics modeling for mobile machine development with AILiveSim.'
};
export const computerMeta: PageMeta =  {
  url: '/Industries/computer-vision',
  title: 'Autonomous Surface Vehicles by AILiveSim: Advanced Solutions',
  description: 'AILiveSim\'s advanced solutions for Autonomous Surface Vehicles. Explore cutting-edge technology for efficient and reliable automation.'
};
export const otherMeta: PageMeta =  {
  url: '/Industries/others',
  title: 'AILiveSim: Comprehensive Simulation for Diverse Applications',
  description: 'AILiveSim capabilities for effortless scenario creation. From security and defense to logistics and automotive, optimize your solutions with precision.'
};

export const careersMeta: PageMeta =  {
  url: '/company/careers',
  title: 'Join AILiveSim: Careers in Dynamic Simulation Technology',
  description: 'Career opportunities at AILiveSim, a startup using game technology for AI algorithm training. Join us for a rewarding now!'
};
export const aboutMeta: PageMeta =  {
  url: '/company/about-us',
  title: 'About AILiveSim: Revolutionizing AI Development with Dynamic Data',
  description: 'Discover AILiveSim - premier AI platform for dynamic data, simulation & testing in maritime, robotics, autonomous vehicles & more.'
};

export const policyMeta: PageMeta =  {
  url: '/privacy-policy',
  title: 'Privacy Policy for AILiveSim: Safeguarding Your Data',
  description: 'Explore our comprehensive privacy policy for AILiveSim. Learn how we protect your data and ensure your privacy.'
};

